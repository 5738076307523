import React, {Fragment} from 'react'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'


function AuthorList ({items}) {
  return (
    <Box>
        {items.map(({author, _key}) => {
          const authorName = author && author.name
          var str = authorName
          var matches = str.match(/\b(\w)/g)
          const authorNameFallback = matches.join('')

          return (
           
            <Box key={_key}>
                  {author && author.image && author.image.asset && (
                    
                    <Avatar
                      src={imageUrlFor(buildImageObj(author.image))
                        .width(100)
                        .height(100)
                        .fit('crop')
                        .url()}
                      alt={authorName}
                    >{authorNameFallback}</Avatar>
                  )}
                  <Typography component='div'>
                    <Box fontSize={12} fontWeight="fontWeightLight">
                      Developer and Educator
                      </Box>
                      <Divider/>
                      <Box fontSize="h6.fontSize" fontWeight="fontWeightLight">
                      {authorName || <em>Missing name</em>}
                    </Box>
                  </Typography>
              </Box>
          
          )
        })}
    </Box>
  )
}

export default AuthorList
