import {format, distanceInWords, differenceInDays} from 'date-fns'
import React, { Fragment } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import Img from 'gatsby-image'
import Typography from '@material-ui/core/Typography'
import { Chip, Box, Container } from '@material-ui/core'
import { DiscussionEmbed } from "disqus-react"
import PortableText from './portableText'
import Wrapper from './wrapper'
import AuthorList from './author-list'


function BlogPost (props) {
  const {_rawBody, authors,  title, id, mainImage, publishedAt, slug, categories} = props
  const dateSegment = format(publishedAt, 'YYYY/MM')
  const path = `/blog/${dateSegment}/${slug.current}/`
  let disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: {
      url: 'https://www.edwinteaches.com/' + `${path}`, 
      identifier: id,
      title: title
    }
  }
  return (
    <Fragment>
      {mainImage && mainImage.asset && (
        <>
        <Box
        style={{color:'#fff', textShadow: '1px 2px 6px #000000',}}
        color='text.primary'
        textAlign='center'
        p={2}
        width='100%'
        position="absolute"
        top='25%'
        zIndex="mobileStepper"
      >
      <Typography variant='h4'>{title}</Typography>
      </Box>
          <Img
          loading={`lazy`}
          fluid={mainImage.asset.fluid}
            alt={mainImage.alt}
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#ffffff',
              backgroundColor: 'rgba(0,0,0,1)',
              textShadow: '1px 2px 6px #000000',
              textAlign: 'center',
              backgroundSize: 'cover',
              backgroundPosition: '8%',
              height: '45vh'
            }}
            backgroundColor={`#102514`}
          />
          
        </>
      )}
      <Wrapper>
       <Container maxWidth='md'>
            {publishedAt && (
              <Box >
             {differenceInDays(new Date(publishedAt), new Date()) > 3
               ? distanceInWords(new Date(publishedAt), new Date())
               : format(new Date(publishedAt), 'MMMM Do, YYYY')}
               </Box>
         )}
         {authors && <AuthorList items={authors} title='Written by' />}
         {categories && (
                <Box>
                <Typography component='div'>
                    <Box fontSize={12} fontWeight="fontWeightLight">
                    Category
                    </Box>
                </Typography>
               {categories.map(category => (
                
              
                <Chip key={category._id} color="primary" variant="outlined" size="small" label={category.title} component={GatsbyLink} to={`/blog/categories/${category.slug.current}`} clickable />
              
                ))}
               </Box>
         )}
              {_rawBody && <PortableText blocks={_rawBody} />}
            
        <Box my={2}><DiscussionEmbed {...disqusConfig} /></Box>
       </Container>
      </Wrapper>
    </Fragment>
  )
}

export default BlogPost
